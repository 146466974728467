import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const selectedClients = [
  { name: "A" },
  { name: "A" },
  { name: "A" },
  { name: "A" },
  { name: "A" },
  { name: "A" },
  { name: "A" },
]

const pad = 4

const IndexPage = () => (
  <Layout>
    <SEO title="Hello" />
    <div className="flex flex-col flex-1 ">
      <div className="container flex flex-col min-h-screen px-12">
        <div className={`flex flex-wrap -mx-${pad} pt-12 pb-24`}>
          <div className={`w-full lg:w-1/6 px-${pad}`}>
            <div
              className="sticky font-normal text-theme-100"
              style={{ top: "3rem" }}
            >
              {/* <h1 className="text-2xl font-normal text-alt-'3rem'0 lg:text-xl">
                Liam <br className="hidden lg:block" />
                Rutherford
              </h1> */}
              <Image className="w-16 rounded-sm" />
            </div>
          </div>
          <div className={`w-full md:w-4/6 lg:w-4/6 xl:w-3/6 px-${pad}`}>
            <h2 className="pt-3 pb-16 text-xl font-light text-alt-700">
              &nbsp;
              {/* Designer <span className="text-alt-800">+</span> Developer */}
            </h2>

            <p className="mb-8 text-2xl font-normal leading-snug text-theme-200">
              Liam Rutherford is <span class="font-medium">digital designer</span> &amp;{" "}
              <span class="font-medium">developer</span> based near{" "}
              <span class="highlight">Edinburgh, Scotland</span>{" "}. <br/>
              He has worked on{" "}
              <span class="font-medium">sites</span>,{" "}
              <span class="font-medium">apps</span> and{" "}
              <span class="font-medium">installations</span> for{" "}
              <span class="link">Nike</span>, <span class="link">Ray-Ban</span>,{" "}
              <span class="link">Beyonce</span>,{" "}
              <span class="link">Kelly Clarkson</span>,{" "}
              <span class="link">Warner Brothers</span>,{" "}
              <span class="link">Universal Music</span>,{" "}
              <span class="link">30 Seconds to Mars</span> &amp;{" "}
              <span class="link">the BBC</span>.
            </p>
            <p className="text-alt-700">
              He also finds it weird speaking about himself in the 3rd person.{" "}
              <span class="strike">No he doesn't.</span> Yes he does.
            </p>
          </div>
          {/* <div className={`lg:w-2/6 px-${pad}`}></div> */}
        </div>

        {/* <div
          className={`flex flex-wrap -mx-${pad} pb-20`}
          data-scroll-fade={true}
        >
          <div className={`lg:w-1/6 px-${pad}`}></div>
          <div className={`w-full md:w-4/6 lg:w-4/6 xl:w-3/6 px-${pad}`}>
            <h2 className="mb-6 text-3xl font-light text-theme-200">
              Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
              auctor. Curabitur blandit tempus porttitor. Aenean eu leo quam.
            </h2>
            <p className="mb-6 text-xl font-light text-theme-200">
              Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
              auctor. Curabitur blandit tempus porttitor. Aenean eu leo quam.
              Pellentesque ornare sem lacinia quam venenatis vestibulum. Cum
              sociis natoque penatibus et magnis dis parturient montes, nascetur
              ridiculus mus.
            </p>
            <p className="italic text-theme-300">
              Maecenas sed diam eget risus varius blandit sit amet non magna.
              Cum sociis natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus. Vestibulum id ligula porta felis euismod
              semper. Nulla vitae elit libero, a pharetra augue. Integer posuere
              erat a ante venenatis dapibus posuere velit aliquet. Nullam quis
              risus eget urna mollis ornare vel eu leo.
            </p>
          </div>
        </div> */}

        {/* <div
          className={`flex flex-wrap -mx-${pad} pb-20`}
          data-scroll-bg="bg-theme-900"
        >
          <div className={`w-full lg:w-1/6 px-${pad} pb-6`}>
            <div
              className="sticky font-normal text-theme-100"
              style={{ top: "3rem" }}
            >
              <h3 className="text-2xl font-normal text-alt-300 lg:text-xl">
                Selected Clients
              </h3>
            </div>
          </div>
          <div className={`w-full lg:w-4/6 px-${pad}`} data-scroll-fade={true}>
            <div className={`flex -mx-${pad} flex-wrap`}>
              {selectedClients.map((client, i) => (
                <div className={`w-1/2 md:w-1/4 px-${pad}`} key={i}>
                  <div
                    className={`bg-alt-900 rounded-sm mb-${pad *
                      2} cursor-pointer ease-in-out transition-slowest transition-color hover:bg-alt-800 `}
                  >
                    <div style={{ paddingBottom: "100%" }}></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className={`flex flex-wrap -mx-${pad} pb-20`}
          data-scroll-bg="bg-alt-900"
        >
          <div className={`w-full lg:w-1/6 px-${pad}`}>
            <div
              className="sticky mb-6 font-normal text-theme-100"
              style={{ top: "3rem" }}
            >
              <h3 className="text-2xl font-normal text-alt-300 lg:text-xl">
                Beyoncé
              </h3>
              <h4 className="text-sm font-light text-alt-800">2013 — 2014</h4>
            </div>
          </div>
          <div className={`w-full md:w-4/6 lg:w-4/6 xl:w-3/6 px-${pad}`}>
            <div className="mb-16" data-scroll-fade={true}>
              <p className="mb-6 text-xl font-normal text-theme-200">
                Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                auctor. Curabitur blandit tempus porttitor. Aenean eu leo quam.
                Pellentesque ornare sem lacinia quam venenatis vestibulum. Cum
                sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus.
              </p>
              <p className="text-theme-300">
                Maecenas sed diam eget risus varius blandit sit amet non magna.
                Cum sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Vestibulum id ligula porta felis euismod
                semper. Nulla vitae elit libero, a pharetra augue. Integer
                posuere erat a ante venenatis dapibus posuere velit aliquet.
                Nullam quis risus eget urna mollis ornare vel eu leo.
              </p>
            </div>

            <div className="mb-16" data-scroll-fade={true}>
              <h3 className="mb-2 text-2xl font-normal text-theme-200">
                This is a subtitle
              </h3>
              <p className="mb-6 text-theme-300">
                Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                auctor. Curabitur blandit tempus porttitor. Aenean eu leo quam.
                Pellentesque ornare sem lacinia quam venenatis vestibulum. Cum
                sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus.
              </p>
              <p className="text-theme-300">
                Maecenas sed diam eget risus varius blandit sit amet non magna.
                Cum sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Vestibulum id ligula porta felis euismod
                semper. Nulla vitae elit libero, a pharetra augue. Integer
                posuere erat a ante venenatis dapibus posuere velit aliquet.
                Nullam quis risus eget urna mollis ornare vel eu leo.
              </p>
            </div>

            <div className="mb-16" data-scroll-fade={true}>
              <h4 className="mb-2 text-xl font-normal text-theme-200">
                This is a smaller subtitle
              </h4>
              <p className="mb-6 text-theme-300">
                Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                auctor. Curabitur blandit tempus porttitor. Aenean eu leo quam.
                Pellentesque ornare sem lacinia quam venenatis vestibulum. Cum
                sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus.
              </p>
              <h4 className="mb-2 text-xl font-normal text-theme-200">
                And this one too.
              </h4>
              <p className="text-theme-300">
                Maecenas sed diam eget risus varius blandit sit amet non magna.
                Cum sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Vestibulum id ligula porta felis euismod
                semper. Nulla vitae elit libero, a pharetra augue. Integer
                posuere erat a ante venenatis dapibus posuere velit aliquet.
                Nullam quis risus eget urna mollis ornare vel eu leo.
              </p>
            </div>
          </div>
        </div>

        <div
          className={`flex flex-wrap -mx-${pad} pb-20`}
          data-scroll-bg="bg-theme-100"
        >
          <div className={`w-full lg:w-1/6 px-${pad}`}>
            <div
              className="sticky mb-6 font-normal text-theme-100"
              style={{ top: "3rem" }}
            >
              <h3 className="text-2xl font-normal text-alt-300 lg:text-xl">
                Nike
              </h3>
              <h4 className="text-sm font-light text-alt-800">2013 — 2017</h4>
            </div>
          </div>
          <div className={`w-full md:w-4/6 lg:w-4/6 xl:w-3/6 px-${pad}`}>
            <div className="mb-16" data-scroll-fade={true}>
              <p className="mb-6 text-xl font-normal text-theme-200">
                Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                auctor. Curabitur blandit tempus porttitor. Aenean eu leo quam.
                Pellentesque ornare sem lacinia quam venenatis vestibulum. Cum
                sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus.
              </p>
              <p className="text-theme-300">
                Maecenas sed diam eget risus varius blandit sit amet non magna.
                Cum sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Vestibulum id ligula porta felis euismod
                semper. Nulla vitae elit libero, a pharetra augue. Integer
                posuere erat a ante venenatis dapibus posuere velit aliquet.
                Nullam quis risus eget urna mollis ornare vel eu leo.
              </p>
            </div>

            <div className="mb-16" data-scroll-fade={true}>
              <h3 className="mb-2 text-2xl font-normal text-theme-200">
                This is a subtitle
              </h3>
              <p className="mb-6 text-theme-300">
                Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                auctor. Curabitur blandit tempus porttitor. Aenean eu leo quam.
                Pellentesque ornare sem lacinia quam venenatis vestibulum. Cum
                sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus.
              </p>
              <p className="text-theme-300">
                Maecenas sed diam eget risus varius blandit sit amet non magna.
                Cum sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Vestibulum id ligula porta felis euismod
                semper. Nulla vitae elit libero, a pharetra augue. Integer
                posuere erat a ante venenatis dapibus posuere velit aliquet.
                Nullam quis risus eget urna mollis ornare vel eu leo.
              </p>
            </div>

            <div className="mb-16" data-scroll-fade={true}>
              <h4 className="mb-2 text-xl font-normal text-theme-200">
                This is a smaller subtitle
              </h4>
              <p className="mb-6 text-theme-300">
                Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                auctor. Curabitur blandit tempus porttitor. Aenean eu leo quam.
                Pellentesque ornare sem lacinia quam venenatis vestibulum. Cum
                sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus.
              </p>
              <h4 className="mb-2 text-xl font-normal text-theme-200">
                And this one too.
              </h4>
              <p className="text-theme-300">
                Maecenas sed diam eget risus varius blandit sit amet non magna.
                Cum sociis natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Vestibulum id ligula porta felis euismod
                semper. Nulla vitae elit libero, a pharetra augue. Integer
                posuere erat a ante venenatis dapibus posuere velit aliquet.
                Nullam quis risus eget urna mollis ornare vel eu leo.
              </p>
            </div>
          </div>
        </div> */}

        <div className={`flex flex-wrap mt-auto -mx-${pad} pb-12`}>
          <div className={`w-full lg:w-1/6 px-${pad} pb-4`}>
            <h3 className="text-alt-'3rem'0 font-normal text-2xl lg:text-xl">
              Say Hi.
            </h3>
          </div>
          <div
            className={`w-full md:w-4/6 lg:w-4/6 xl:w-3/6 px-${pad} text-alt-700`}
          >
            <a href="mailto:hello@liamr.com">Email me.</a>&nbsp;&nbsp;<a href="https://twitter.com/liamr">Tweet me.</a>
            
          </div>
          {/* <div className={`w-2/6 px-${pad}`}></div> */}
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
